<template>
  <div align="center">
      <h1>  {{ this.titre }} </h1>
      <p>Coach: {{ this.coach }} </p>
      <p>Debut: {{this.debut}} </p>
    
  </div>
</template>

<script>
export default {
    name:"callHeader",
    props:{
        titre:"",
        coach:"",
        debut:"",
    },
    methods:{
       
    }
}
</script>

<style>

</style>